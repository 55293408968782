.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

.container {
  max-width: '100%';
  width: 100%;
}

#root {
    width: 100%;
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.result-block-container .result-block {
  opacity: 1;
}

.brand-logo {
  display: inline-flex !important;
  width: 64px;
}

.toc {
  position: sticky;
  position: -webkit-sticky; /* For Safari */
  top: 120px; /* How far down the page you want your ToC to live */

  /* Give table of contents a scrollbar */
  max-height: calc(100vh - 40px);
  overflow: auto;
  float:right;
  background-color:#f8f9fa;
  border: solid;
  border-color:#e9ebf2;
  border-width: 2px;
  border-radius:10px;
  padding:5px;
}

li.active > a {
  font-weight: bold;
}

.mensi {
  max-width:1100px;
  margin: auto;
}

.right {
  float:right;
  padding-right:40px;
}


.left {
  float:left;
  width:40%;
  padding-left:40px;
}
